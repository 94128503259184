// src/pages/CreateMultipleTickets.js
import React from 'react';
import CreateMultipleTickets from '../components/CreateMultipleTickets';
const CreateMultipleTicketsPage = () => {
  return (
    <div>
      <CreateMultipleTickets></CreateMultipleTickets>
    </div>
  );
};

export default CreateMultipleTicketsPage;