import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Header from '../components/Header';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const CreateNewTicket = () => {
  const [horaIdaExcel, setHoraIdaExcel] = useState(new Date());
  const [horaVoltaExcel, setHoraVoltaExcel] = useState(new Date());
  const [horaIdaGB, setHoraIdaGB] = useState(new Date());
  const [horaIdaGBID, setHoraIdaGBID] = useState(0);
  const [horaVoltaGB, setHoraVoltaGB] = useState(new Date());
  const [horaVoltaGBID, setHoraVoltaGBID] = useState(0);
  const [nome, setNome] = useState("EVENTO UNESCO");
  const [email, setEmail] = useState('');
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState('');
  const [availableHoursIda, setAvailableHoursIda] = useState([]);
  const [availableHoursVolta, setAvailableHoursVolta] = useState([]);
  const [idaVolta, setIdaVolta] = useState(true); // State for Ida e Volta checkbox
  const [isLoading, setIsLoading] = useState(false)

  const formatDateTime = (dateTime) => {
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hour = String(dateTime.getHours()).padStart(2, '0');
    const minute = String(dateTime.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

  useEffect(() => {
    const fetchRoutes = async () => {
      const apiToken = localStorage.getItem('api_token');
      try {
        const response = await fetch('https://back.getbus.eu/api/driver/get_driver_routes?api_token=' + apiToken);
        const data = await response.json();
        setRoutes(data);
      } catch (error) {
        console.error('Error fetching routes:', error);
      }
    };

    fetchRoutes();
  }, []);

  useEffect(() => {
    if (selectedRoute) {
      const fetchAvailableHours = async () => {
        try {
          const today = new Date();
          const todayDate = today.toISOString().split('T')[0];
          const dayOfWeek = today.getDay();
          const apiToken = localStorage.getItem('api_token');

          console.log(todayDate);
          console.log(dayOfWeek);

          const response = await fetch('https://back.getbus.eu/api/dev/routes?api_token=' + apiToken, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              apiToken: apiToken,
              returnDate: todayDate,
              departureDate: todayDate,
              departureDay: dayOfWeek,
              returnDay: dayOfWeek,
              trip_type: 2,
              route_id: selectedRoute,
            }),
          });

          const data = await response.json();
          if (data.status) {
            setAvailableHoursIda(data.data.singleTripRoutes.timeslots);
          }
        } catch (error) {
          console.error('Error fetching available hours:', error);
        }
      };

      fetchAvailableHours();
    }
  }, [selectedRoute]);

  useEffect(() => {
    if (selectedRoute) {
      const fetchAvailableHoursVolta = async () => {
        try {
          const today = new Date();
          const todayDate = today.toISOString().split('T')[0];
          const dayOfWeek = today.getDay();
          const apiToken = localStorage.getItem('api_token');

          console.log(todayDate);
          console.log(dayOfWeek);

          const response = await fetch('https://back.getbus.eu/api/dev/routes?api_token=' + apiToken, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              apiToken: apiToken,
              returnDate: todayDate,
              departureDate: todayDate,
              departureDay: dayOfWeek,
              returnDay: dayOfWeek,
              trip_type: 2,
              route_id: selectedRoute + 1,
            }),
          });

          const data = await response.json();
          if (data.status) {
            setAvailableHoursVolta(data.data.singleTripRoutes.timeslots);
          }
        } catch (error) {
          console.error('Error fetching available hours:', error);
        }
      };

      fetchAvailableHoursVolta();
    }
  }, [selectedRoute]);

  async function saveTicket(ticketData) {
    setIsLoading(true)
    /* const baseURL = 'https://back.dev.getbus.eu/api/dev';
    const url = `${baseURL}/save_new_ticket`; */
    const apiToken = localStorage.getItem('api_token');
    try {
      ticketData.apiToken = apiToken;
      const response = await fetch('https://back.getbus.eu/api/dev/save_new_ticket?api_token=' + apiToken, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(ticketData),
            });
      
      const data = await response.json();
          if (data.status) {
            console.log(data);
          }
          setIsLoading(false)
          return data
      } catch (error) {
        console.error('Error fetching available hours:', error);
        setIsLoading(false)
      }
  }

  const findClosestHourIda = (dateTime) => {
    const inputTime = dateTime.getHours() * 60 + dateTime.getMinutes();
    let closestTime = null;
    let minDifference = Infinity;
    let dateId = null;

    availableHoursIda.forEach((timeslot) => {
      const [hour, minute] = timeslot.partida.split(':').map(Number);
      const timeslotTime = hour * 60 + minute;
      const difference = timeslotTime - inputTime;

      if (difference > 0 && difference < minDifference) {
        minDifference = difference;
        closestTime = timeslot.partida;
        dateId = timeslot.id;
      }
    });

    return [closestTime, dateId];
  };

  const findClosestHourVolta = (dateTime) => {
    const inputTime = dateTime.getHours() * 60 + dateTime.getMinutes();
    let closestTime = null;
    let minDifference = Infinity;
    let dateId = null;

    availableHoursVolta.forEach((timeslot) => {
      const [hour, minute] = timeslot.partida.split(':').map(Number);
      const timeslotTime = hour * 60 + minute;
      const difference = timeslotTime - inputTime;

      if (difference > 0 && difference < minDifference) {
        minDifference = difference;
        closestTime = timeslot.partida;
        dateId = timeslot.id;
      }
    });

    return [closestTime, dateId];
  };

  const handleHoraIdaExcelChange = (dateTime) => {
    setHoraIdaExcel(dateTime);
    const closestHour = findClosestHourIda(dateTime);
    if (closestHour[0]) {
      const [hour, minute] = closestHour[0].split(':').map(Number);
      const updatedDate = new Date(dateTime);
      updatedDate.setHours(hour, minute);
      setHoraIdaGB(updatedDate);
      setHoraIdaGBID(closestHour[1]);
    }
  };

  const handleHoraVoltaExcelChange = (dateTime) => {
    setHoraVoltaExcel(dateTime);
    const closestHour = findClosestHourVolta(dateTime);
    if (closestHour[0]) {
      const [hour, minute] = closestHour[0].split(':').map(Number);
      const updatedDate = new Date(dateTime);
      updatedDate.setHours(hour, minute);
      setHoraVoltaGB(updatedDate);
      setHoraVoltaGBID(closestHour[1]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedHoraIdaGB = formatDateTime(horaIdaGB);
    const formattedHoraVoltaGB = formatDateTime(horaVoltaGB);

    const ticketData = {
      name: "EVENTO UNESCO",
      email: email,
      route_id: selectedRoute,
      trip_type: idaVolta ? 1 : 2, // 1 for ida_volta, 2 for ida
      adult_count: 1,
      child_count: 0,
      departure_date: formattedHoraIdaGB.toString().substring(0, 10),
      total_price: idaVolta ? 16 : 9 ,
      horario_ida: horaIdaGBID,
      horario_volta: idaVolta ? horaVoltaGBID : null,
      return_date: idaVolta ? formattedHoraVoltaGB.toString().substring(0, 10) : null
    };
    // Handle form submission logic here
    console.log('Ticket Data:', ticketData);
    saveTicket(ticketData)
  };

  return (
    <div>
      <Header />
      <div className="container mt-4">
        <div className="col">
          <label htmlFor="route" className="form-label">Route</label>
          <select
            className="form-select"
            id="route"
            value={selectedRoute}
            onChange={(e) => setSelectedRoute(e.target.value)}
            required
          >
            <option value="" disabled>Select a route</option>
            {routes.map((route) => (
              <option key={route.id} value={route.id}>
                {route.name}
              </option>
            ))}
          </select>
        </div>

        <h2>Create New Ticket</h2>
        <form onSubmit={handleSubmit}>
          
          <div className="mb-3 row">
            <div className="col">
              <label htmlFor="horaIdaExcel" className="form-label">Hora de ida excel</label>
              <DateTimePicker
                disableClock={true}
                onChange={handleHoraIdaExcelChange}
                value={horaIdaExcel}
                className="form-control"
                format="dd/MM/yyyy HH:mm"
                required
              />
            </div>
            {idaVolta && (
              <div className="col">
                <label htmlFor="horaVoltaExcel" className="form-label">Hora de volta excel</label>
                <DateTimePicker
                  disableClock={true}
                  onChange={handleHoraVoltaExcelChange}
                  value={horaVoltaExcel}
                  className="form-control"
                  format="dd/MM/yyyy HH:mm"
                  required
                />
              </div>
            )}
            <div className="col">
              <label htmlFor="horaIdaGB" className="form-label">Hora de ida gb</label>
              <DateTimePicker
                disableClock={true}
                onChange={setHoraIdaGB}
                value={horaIdaGB}
                className="form-control"
                format="dd/MM/yyyy HH:mm"
                required
              />
            </div>
            {idaVolta && (
              <div className="col">
                <label htmlFor="horaVoltaGB" className="form-label">Hora de volta gb</label>
                <DateTimePicker
                  disableClock={true}
                  onChange={setHoraVoltaGB}
                  value={horaVoltaGB}
                  className="form-control"
                  format="dd/MM/yyyy HH:mm"
                  required
                />
              </div>
            )}
          </div>
          <div className="mb-3 row">
        
            <div className="col-1">
              <label htmlFor="idaVolta" className="form-check-label">Ida e Volta</label>
              <input
                type="checkbox"
                className="form-check-input"
                id="idaVolta"
                checked={idaVolta}
                onChange={(e) => setIdaVolta(e.target.checked)}
              />
            </div>
       
            <div className="col">
              <label htmlFor="nome" className="form-label">Nome</label>
              <input
                type="text"
                className="form-control"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
            </div>
            <div className="col">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col d-flex align-items-end">
              { isLoading ? 
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div> :  
              <button type="submit" className="btn btn-primary w-100">Create Ticket</button>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewTicket;