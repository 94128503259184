// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAaBirGVMYLo6xV8EtbYbNcKf7bmBDn6ME",
    authDomain: "chrome-horizon-269811.firebaseapp.com",
    projectId: "chrome-horizon-269811",
    storageBucket: "chrome-horizon-269811.appspot.com",
    messagingSenderId: "693529500208",
    appId: "1:693529500208:web:c53930d87e53168cde2bf5",
    measurementId: "G-0XGV2L82T2"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

export { db, auth };