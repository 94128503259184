import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import DateTimePicker from 'react-datetime-picker'; // Assuming you have installed this library
import { auth, db } from '../firebase.js';
import { collection, getDocs,setDoc, doc, getDoc, Timestamp } from 'firebase/firestore';



/* const ticketData = {
      name: "UCCN",
      email: email,
      route_id: selectedRoute,
      trip_type: idaVolta ? 1 : 2, // 1 for ida_volta, 2 for ida
      adult_count: 1,
      child_count: 0,
      departure_date: formattedHoraIdaGB.toString().substring(0, 10),
      total_price: idaVolta ? 16 : 9 ,
      horario_ida: horaIdaGBID,
      horario_volta: idaVolta ? horaVoltaGBID : null,
      return_date: idaVolta ? formattedHoraVoltaGB.toString().substring(0, 10) : null
    }; */

const CreateMultipleTickets = () => {
  const [listaAux, setListaAux] = useState([]);
  const [jsonDataa, setJsonDataa] = useState([])
  //const [availableHoursIda, setAvailableHoursIda] = useState([]);
  //const [availableHoursVolta, setAvailableHoursVolta] = useState([]);

  const saveJsonToFirestore = async (data) => {
    try {
      await setDoc(doc(db, "inserirtickets", "tickets"), {data}); // "users" is the collection, "user1" is the document ID
      console.log("Document successfully written!");
    } catch (e) {
      console.error("Error writing document: ", e);
    }
  };

   const getDataJson = async () => {
    try {
      const querySnapshot = await getDoc(doc(db, "inserirtickets", "tickets"));
      const docs = querySnapshot.data()
      var lista123 = [];
      docs.data.forEach((elem,index) => {
        lista123.push({
          email: elem.email,
          enviado: elem.enviado,
          horaChegada: new Date(elem.horaChegada.seconds * 1000),
          horaIdaGB: new Date(elem.horaIdaGB.seconds * 1000),
          horaIdaGBID: elem.horaIdaGBID,
          horaPartida: elem.horaPartida == null ? null : new Date(elem.horaPartida.seconds * 1000),
          horaVoltaGB: new Date(elem.horaVoltaGB.seconds * 1000),
          horaVoltaGBID: elem.horaVoltaGBID,
          idaVolta: elem.idaVolta,
          sent: elem.sent

        })
      })
      setJsonDataa(lista123)
      
    } catch (e) {
      console.error("Error writing document: ", e);
    }
  }; 

 /*  useEffect(() =>{
     getDataJson() 
  }, []) */

  useEffect(() => {
     getDataJson()
    var listaAuxiliar = [];
    jsonData.forEach((elemn, index) => {
      var idaVolta = false;
      var newHoraPartida = null;
      var newHoraChegada = null;

      if (elemn['Chegada (Data)'] !== undefined) {
        var chegadaFormatada = elemn['Chegada (Data)'].substring(3, 6) + elemn['Chegada (Data)'].substring(0, 3) + elemn['Chegada (Data)'].substring(6, 10)
        newHoraChegada = chegadaFormatada + ' ' + elemn['Chegada (Hora)'].substring(0, 5)
      }
      if (elemn['Partida (Data)'] !== undefined) {
        var partidaFormatada = elemn['Partida (Data)'].substring(3, 6) + elemn['Partida (Data)'].substring(0, 3) + elemn['Partida (Data)'].substring(6, 10)
        idaVolta = true
        newHoraPartida = partidaFormatada + ' ' + elemn['Partida ( Hora)'].substring(0, 5)
      }
      listaAuxiliar.push({
        horaChegada: new Date(newHoraChegada),
        horaPartida: new Date(newHoraPartida),
        sent: false,
        idaVolta: idaVolta,
        enviado: false,
        email: elemn.Email,
        horaVoltaGBID: null,
        horaVoltaGB: null,
        horaIdaGB: null,
        horaIdaGBID: null

      })
    })

     const fetchAvailableHoursIda = async () => {
      try {
        const today = new Date();
        const todayDate = today.toISOString().split('T')[0];
        const dayOfWeek = today.getDay();
        const apiToken = localStorage.getItem('api_token');

        const response = await fetch('https://back.getbus.eu/api/dev/routes?api_token=' + apiToken, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            trip_type: 2,
            route_id: 2,
          }),
        });

        const data = await response.json();
        if (data.status) {
          console.log("DATAS CHEGGADA", data.data.singleTripRoutes.timeslots)
          //setAvailableHoursIda(data.data.singleTripRoutes.timeslots);
          listaAuxiliar.forEach((elemn, index) => {
           
          
            let    [closestTimeCh, dateIdCh,somahora] = findClosestHourIda(elemn.horaChegada, data.data.singleTripRoutes.timeslots);
              
            
            // Extract year, month, and day from the existing date object
            var date = listaAuxiliar[index].horaChegada;
            var year = date.getFullYear();
            var month = date.getMonth(); // Note: getMonth() returns a zero-based index (0 for January, 1 for February, etc.)
            var day = date.getDate() + somahora;
        
            // Extract hours and minutes from closestTimeCh
            var hours = parseInt(closestTimeCh.substring(0, 2));
            var minutes = parseInt(closestTimeCh.substring(3, 5));
        
            // Create a new Date object with the combined date and time
            var combinedDateTime = new Date(year, month, day, hours, minutes);
        
            // Update listaAuxiliar with the new Date object and other details
            listaAuxiliar[index].horaIdaGB = combinedDateTime;
            listaAuxiliar[index].horaIdaGBID = dateIdCh;
          });
          
        }
      } catch (error) {
        console.error('Error fetching available hours:', error);
      }
    };

    const fetchAvailableHoursVolta = async () => {
      try {
        const today = new Date();
        const todayDate = today.toISOString().split('T')[0];
        const dayOfWeek = today.getDay();
        const apiToken = localStorage.getItem('api_token');


        const response = await fetch('https://back.getbus.eu/api/dev/routes?api_token=' + apiToken, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            trip_type: 2,
            route_id: 1,
          }),
        });

        const data = await response.json();
        if (data.status) {
          console.log("DATAS PARTIDA", data.data.singleTripRoutes.timeslots)
          //setAvailableHoursVolta(data.data.singleTripRoutes.timeslots);
          listaAuxiliar.forEach((elemn, index) => {
            
            
       
              
            let [closestTimeCh, dateIdCh,somahora] = findClosestHourVolta(elemn.horaPartida, data.data.singleTripRoutes.timeslots);
              
            var date = listaAuxiliar[index].horaPartida;

            // Extract year, month, and day from the existing date object
            var year = date.getFullYear();
            var month = date.getMonth(); // Note: getMonth() returns a zero-based index (0 for January, 1 for February, etc.)
            var day = date.getDate() + somahora;
          
            // Extract hours and minutes from closestTimeCh
            var hours = parseInt(closestTimeCh.substring(0, 2));
            var minutes = parseInt(closestTimeCh.substring(3, 5));
          
            // Create a new Date object with the combined date and time
            var combinedDateTime = new Date(year, month, day, hours, minutes);
          
            // Update listaAuxiliar with the new Date object and other details
            listaAuxiliar[index].horaVoltaGB = combinedDateTime;
            listaAuxiliar[index].horaVoltaGBID = dateIdCh;
          });
          console.log(listaAuxiliar)
        }
      } catch (error) {
        console.error('Error fetching available hours:', error);
      }
    };

    fetchAvailableHoursIda().then((value) => {
      fetchAvailableHoursVolta().then((value) => {
        setListaAux(listaAuxiliar);
      }) 
    })
     
    
  },  /* [jsonDataa] */  [] ) ;

  useEffect(() => {},[jsonDataa])

  const findClosestHourIda = (dateTime,array) => {
    const inputTime = dateTime.getHours() * 60 + dateTime.getMinutes();
    let closestTime = null;
    let minDifference = Infinity;
    let dateId = null;

    if(dateTime.getHours() >= 23 ||(  dateTime.getHours() >= 22 && dateTime.getMinutes()>= 30)) {
      return ["00:45", 64,1 ];
    } else {

    array.forEach((timeslot) => {
      timeslot.partida = timeslot.partida.replace(".", ':')
      const [hour, minute] = timeslot.partida.split(':').map(Number);
      const timeslotTime = hour * 60 + minute;
      const difference = timeslotTime - inputTime;

      if (difference > 30 && difference < minDifference) {
        minDifference = difference;
        closestTime = timeslot.partida;
        dateId = timeslot.id;
      }
    });}

    return [closestTime, dateId, 0];
  };

  const findClosestHourVolta = (dateTime, array) => {
    const inputTime = dateTime.getHours() * 60 + dateTime.getMinutes();
    let closestTime = null;
    let minDifference = Infinity;
    let dateId = null;
  
    if(dateTime.getHours() <= 5) {
      return ["23:45", 62, -1];
    } else {
      for (const timeslot of array) {
        timeslot.partida = timeslot.partida.replace(".", ':')
        const [hour, minute] = timeslot.partida.split(':').map(Number);
        const timeslotTime = hour * 60 + minute;
        const difference = timeslotTime - inputTime;
  
        if (difference <= -120 && Math.abs(difference) < Math.abs(minDifference)) {
          minDifference = difference;
          closestTime = timeslot.partida;
          dateId = timeslot.id;
        }
      }
      return [closestTime, dateId, 0];
    }
  };


  const formatDateTime = (dateTime) => {
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hour = String(dateTime.getHours()).padStart(2, '0');
    const minute = String(dateTime.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

  async function saveTicket(ticketData) {
    /* const baseURL = 'https://back.dev.getbus.eu/api/dev';
    const url = `${baseURL}/save_new_ticket`; */
    const apiToken = localStorage.getItem('api_token');
    try {
      
      const response = await fetch('https://back.getbus.eu/api/vouchers/email_voucher', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(ticketData),
            });
      
      const data = await response.json();
      console.log(data);
          if (data.status) {
            return data
          } else {
            return null;
          }
          
      } catch (error) {
        console.error('Error fetching available hours:', error);
       return null;
      }
  }

  const handleSubmit = (e,ticket,index) => {
    e.preventDefault();

    const formattedHoraIdaGB = formatDateTime(ticket.horaIdaGB);
    const formattedHoraVoltaGB = formatDateTime(ticket.horaVoltaGB);
    
   

    const ticketData = {
      name: "UCCN",
      email: ticket.email,
      route_id: 2,
      trip_type: ticket.idaVolta ? 1 : 2, // 1 for ida_volta, 2 for ida
      adult_count: 1,
      child_count: 0,
      departure_date: formattedHoraIdaGB.toString().substring(0, 10),
      total_price: 0 ,
      horario_ida: ticket.horaIdaGBID,
      horario_volta: ticket.idaVolta ? ticket.horaVoltaGBID : null,
      return_date: ticket.idaVolta ? formattedHoraVoltaGB.toString().substring(0, 10) : null
    };
  
    // Handle form submission logic here
    console.log('Ticket Data:', ticketData);
    saveTicket(ticketData).then((value) => {
      if(value != null) {
        const newJsonDataa = [...jsonDataa];
        // Update the specific field of the specific object
        console.log("newJsonDataa" , newJsonDataa[index])
        newJsonDataa[index]['sent'] = true;
        setJsonDataa(newJsonDataa);
        saveJsonToFirestore(newJsonDataa).then((value) => {
          getDataJson()
        })
        //()
      }
    })
  };

  const handleDateTimeChange = (date, index, field) => {
    const newListaAux = [...listaAux];
    newListaAux[index][field] = date;
    setListaAux(newListaAux);
  }

 /*  const handleSubmit = (e, ticket, index) => {
    e.preventDefault();
    // Implement your submission logic here (e.g., API call, etc.)
    console.log('Ticket Data:', ticket);
    console.log('Index:', index);
  }; */

  return (
    <div>
      <Header />
      <div className="container mt-4">
        <h2>Create Multiple Tickets</h2>
        <button onClick={() => {
              /* console.log(jsonDataa)  */
              saveJsonToFirestore(listaAux)
        }} > ASD ASD ASD </button>
        {jsonDataa.map((ticket, index) => (

          <form style={{ paddingBottom: "20px" }} key={index} onSubmit={(e) => handleSubmit(e, ticket, index)}>
            <hr></hr>
            <div style={{ paddingTop: "20px" }} className="mb-3 row">

              <div className="col">
                <label className="form-label">Hora de Chegada Excel</label>
                <DateTimePicker
                  id={'horapartida' + index}
                  value={ticket.horaChegada}
                  onChange={(date) => handleDateTimeChange(date, index, 'horaChegada')}
                  disableCalendar
                  disableClock
                  className="form-control"
                  format="dd/MM/yyyy HH:mm"
                />
              </div>
              <div className="col">
                <label className="form-label">Hora de Chegada GB</label>
                <DateTimePicker
                  id={'horapartidagb' + index}
                  value={ticket.horaIdaGB}
                  className="form-control"
                  format="dd/MM/yyyy HH:mm"
                  disabled
                />
              </div>
              <div hidden={!ticket.idaVolta} className="col">
                <label className="form-label">Hora de partida Excel</label>
                <DateTimePicker
                  id={'horachegada' + index}
                  value={ticket.horaPartida}
                  className="form-control"
                  format="dd/MM/yyyy HH:mm"
                  disabled
                />
              </div>
              <div hidden={!ticket.idaVolta} className="col">
                <label className="form-label">Hora de partida Gb</label>
                <DateTimePicker
                  id={'horachegadagb' + index}
                  value={ticket.horaVoltaGB}
                  className="form-control"
                  format="dd/MM/yyyy HH:mm"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-1">
                <label htmlFor="idaVolta" className="form-check-label">Ida e Volta</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={"idaVolta" + index}
                  defaultChecked={ticket.idaVolta}
                  onChange={(e) => { document.getElementById("idaVolta" + index).value = !e.currentTarget.checked; }}
                />
              </div>
              <div className="col">
                <label className="form-label">Nome</label>
                <input
                  id={"nome" + index}
                  type="text"
                  className="form-control"
                  value={"UCCN"}
                  
                />
              </div>
              <div className="col">
                <label className="form-label">Email</label>
                <input
                  id={"email" + index}
                  type="email"
                  className="form-control"
                  value={ticket.email}
                  
                />
              </div>
              <div className="col">
                <label className="form-label">Enviado</label>
                <div id={"enviado" + index}>
                  {ticket.sent ? <p style={{ color: "green" }} >Enviado</p> : <p style={{ color: "red" }} >Não enviado</p>}
                </div>
              </div>

              <div className="col d-flex align-items-end">
                <button id={"button" + index} type="submit" className="btn btn-primary w-100">
                  Create Ticket
                </button>
              </div>
            </div>
          </form>
        ))}
      </div>
    </div>
  );
};

export default CreateMultipleTickets;


const jsonData = [

   {
    "Nome Completo": "Meor Abdullah Zaidi Bin Meor Razali",
    "Email": "manuelbarrosnpg@gmail.com",
    "Chegada (Data)": "29\/06\/2024",
    "Chegada (Hora)": "10:55:00",
    "Partida (Data)": "04\/07\/2024",
    "Partida ( Hora)": "16:50:00"
   },
  {
   "Nome Completo": "Meor Abdullah Zaidi Bin Meor Razali",
   "Email": "creative_city_ipoh@mbi.gov.my",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Ahmad Hariri Bin Kamarudin",
   "Email": "farhanafaizul2399@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Olav Brostrup Müller",
   "Email": "olav.brostrup.muller@lillehammer.kommune.no",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:40:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Witiya Pittungnapoo",
   "Email": "pwitiya@googlemail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Inkyoung Kim",
   "Email": "kik9133@korea.kr",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "22:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:00:00"
  },
  {
   "Nome Completo": "Jiabao (Cindy) Lee",
   "Email": "wuhan_design@yeah.net",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "21:50:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "19:40:00"
  },
  {
   "Nome Completo": "Michelle Symes",
   "Email": "michelle.symes@bendigo.vic.gov.au",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:30:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Harouna Ramde",
   "Email": "ramdeharouna@yahoo.fr",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "28\/06\/2024",
   "Partida ( Hora)": "19:50:00"
  },
  {
   "Nome Completo": "BYUNG HOON JEONG",
   "Email": "bhjeong55@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "22:45:00",
   "Partida (Data)": "11\/07\/2024",
   "Partida ( Hora)": "11:10:00"
  },
  {
   "Nome Completo": "DADDY NSABA",
   "Email": "felixkitungwa1@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "21:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:45:00"
  },
  {
   "Nome Completo": "Trent Sullivan",
   "Email": "mayoraloffice@geelongcity.vic.gov.au",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "09\/07\/2024",
   "Partida ( Hora)": "11:05:00"
  },
  {
   "Nome Completo": "Theresa Freydl",
   "Email": "theresa.freydl@cis.at",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "18:00:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:35:00"
  },
  {
   "Nome Completo": "Sulaiman Alqifari",
   "Email": "sag@buccn.sa",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "06:00:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "22:45:00"
  }, 
  {
   "Nome Completo": "Thi Ngoc Cam Truong",
   "Email": "hoiancreativecity@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:35:00"
  },
  {
   "Nome Completo": "Tennille Bradley",
   "Email": "tennille.bradley@geelongcity.vic.gov.au",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "11:10:00"
  },
  {
   "Nome Completo": "Sami Ylisaari",
   "Email": "sami.ylisaari@ouka.fi",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "19:00:00",
   "Partida (Data)": "13\/07\/2024",
   "Partida ( Hora)": "09:55:00"
  },
  {
   "Nome Completo": "Rumaizi Bin Baharin",
   "Email": "zulosman@mbi.gov.my",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Inggit Soraya",
   "Email": "inggitsoraya@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "05:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "15:15:00"
  },
  {
   "Nome Completo": "Sandra Wall",
   "Email": "sandra.wall@norrkoping.se",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:10:00",
   "Partida (Data)": "09\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
   {
   "Nome Completo": "Promporn Jindawong Nateharn",
   "Email": "promporn.jn@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "22:45:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Khalid Bo Shal",
   "Email": "bowshal571@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:20:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "11:00:00"
  },
  {
   "Nome Completo": "POLLAVAT PRAPATTONG",
   "Email": "pollavat.pra@mfu.ac.th",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "22:45:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Yoshinori Sadai",
   "Email": "ysadai229@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "11:20:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "06:00:00"
  },
  {
   "Nome Completo": "Philippe Sueur",
   "Email": "ldubois@cdarts.enghien95.fr",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "01\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Laurent Guidi",
   "Email": "LGuidi@enghien95.fr",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "02\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "GREGOIRE PENAVAIRE",
   "Email": "penavairegregoire@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "NUJURAULLY MAMODE ISSOP",
   "Email": "portlouiscity@municipalcouncil.org",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "17:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "18:15:00"
  },
  {
   "Nome Completo": "ROUKAYATOU Mohamed",
   "Email": "mohamedroukayatou2024@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:30:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Hideyuki Miyagi",
   "Email": "hidebu1207@outlook.jp",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "11:20:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "06:00:00"
  },
  {
   "Nome Completo": "Worawid Chaisawad",
   "Email": "cmpaocrafts@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Harsh Singh",
   "Email": "kumarankurgupta113@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "06:00:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "07:20:00"
  },
  {
   "Nome Completo": "Shuang Yuan",
   "Email": "2308123125@qq.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Eleonora Gogolashvili",
   "Email": "Eleonora_gogolashvili@yahoo.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "07:25:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "08:10:00"
  },
  {
   "Nome Completo": "Siriwan Buranasing",
   "Email": "tttor_kub@hotmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Danni Yang",
   "Email": "danni.yang@njliterature.org",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Somchart Wattanakla",
   "Email": "Bluenoswift@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Delphin FONHAN",
   "Email": "venance201@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:30:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Witthayalak Samjai",
   "Email": "witthayalak0302@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Nipaphorn Intharat",
   "Email": "Ni25233in@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Van Son Nguyen",
   "Email": "vu.nnguyen90@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:35:00"
  },
  {
   "Nome Completo": "Van Vinh Nguyen",
   "Email": "nvanhkhoa148@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:35:00"
  },
  {
   "Nome Completo": "Jarunee Wattanakla",
   "Email": "witiyap@nu.ac.th",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Bertsetseg Zoljargal",
   "Email": "zzbertsetsegg@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "05:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "10:50:00"
  },
  {
   "Nome Completo": "Courtet Mathias",
   "Email": "m.courtet@caen.fr",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "14:30:00",
   "Partida (Data)": "02\/06\/2024",
   "Partida ( Hora)": "20:25:00"
  },
  {
   "Nome Completo": "João Cornélio Gomes Correia",
   "Email": "joaocorneliogomescorreia@yahoo.com.br",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "21:45:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "10:15:00"
  },
  {
   "Nome Completo": "Maria da Conceição Da Silva Évora",
   "Email": "lucreciobebe@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "23:40:00",
   "Partida (Data)": "11\/07\/2024",
   "Partida ( Hora)": "10:15:00"
  },
  {
   "Nome Completo": "D'ALMEIDA RUFINO",
   "Email": "agossadou.boris.fvc@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:30:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Nana GVICHIYA",
   "Email": "icd@spbcb.ru",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "14:35:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "09:10:00"
  },
  {
   "Nome Completo": "Mahoulouth DIAKHATE",
   "Email": "papediakhate@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "22:40:00",
   "Partida (Data)": "29\/06\/2024",
   "Partida ( Hora)": "22:40:00"
  },
  {
   "Nome Completo": "Shobha Sikarwar",
   "Email": "sagarmragendradtcfes@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "06:00:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "07:20:00"
  },
  {
   "Nome Completo": "Haya Al Sada",
   "Email": "haya.alsada@culture.gov.bh",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "09:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "09:55:00"
  },
  {
   "Nome Completo": "Munkhnasan Munkhjargal",
   "Email": "moogal.naog@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "05:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "10:50:00"
  },
  {
   "Nome Completo": "Otgonzul Khurelbaatar",
   "Email": "Otgonzulkh@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "05:15:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "10:50:00"
  },
  {
   "Nome Completo": "Diana MAIOROVA",
   "Email": "mayorova@spbcb.ru",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "14:35:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "09:10:00"
  },
  {
   "Nome Completo": "Shawn Lee",
   "Email": "lee@wecans.cn",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "09:25:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "09:50:00"
  },
  {
   "Nome Completo": "Ting Xu",
   "Email": "756838@qq.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "04:20:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "09:35:00"
  },
  {
   "Nome Completo": "Tipsukon Choungthong",
   "Email": "bangkokcityofdesign@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "04:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "18:25:00"
  },
  {
   "Nome Completo": "Yahiriana Moros",
   "Email": "moros.cancilleria@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "08:15:00"
  },
  {
   "Nome Completo": "Sally Abu Bakr",
   "Email": "s.abubakr@ramallah.ps",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "22:20:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "04:00:00"
  }, 
  {
   "Nome Completo": "Milan Duric",
   "Email": "dragana.zagorcic@novisad.rs",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "16:30:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "21:20:00"
  },

   {
   "Nome Completo": "Anuttra Chaiyanandha",
   "Email": "anuttra.c@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "04:25:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "06:25:00"
  },
  {
   "Nome Completo": "Sakeson Paenphai",
   "Email": "SAKESON22@yahoo.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "04:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:25:00"
  },
  {
   "Nome Completo": "Meri Amirkhanian",
   "Email": "meri.amirkhanian@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "18:00:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:25:00"
  },
  {
   "Nome Completo": "José Benito Irady Arias",
   "Email": "yahirim@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "08:15:00"
  },
  {
   "Nome Completo": "Zulkefli Bin Osman",
   "Email": "zulkefliosmanjpbd@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Hector Galvez",
   "Email": "avazquezr@baja.gob.mx",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "23:20:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "09:00:00"
  },
  {
   "Nome Completo": "Ibrahim H. Alshubaith",
   "Email": "ibshubaith@gmail.com",
   "Chegada (Data)": "29\/06\/2024",
   "Chegada (Hora)": "10:55:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "11:00:00"
  },
  {
   "Nome Completo": "Ioseb Khakhaleishvili",
   "Email": "i.khakhaleishvili@kutaisi.gov.ge",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:10:00"
  },
  {
   "Nome Completo": "David Ryding",
   "Email": "cityofliterature@wheelercentre.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:15:00"
  },
  {
   "Nome Completo": "Francis Sokomba",
   "Email": "francissokomba70@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Stesha Brandon",
   "Email": "executive@seattlecityoflit.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Zainab Mohammed",
   "Email": "xaynab@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Eriko Esaka",
   "Email": "esaka@creative-nagoya.jp",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Tatsuaki Koyama",
   "Email": "koyama-tatsuaki@gw.city.sasayama.hyogo.jp",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Maud Boissac",
   "Email": "maud.boissac@ville-cannes.fr",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:10:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "18:20:00"
  },
  {
   "Nome Completo": "Hannah Trevarthen",
   "Email": "hannah@nottmcityoflit.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:00:00"
  },
  {
   "Nome Completo": "Ivan Wadeson",
   "Email": "ivan.wadeson@manchestercityofliterature.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "20:30:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "10:30:00"
  },
  {
   "Nome Completo": "Johann Llamas Vázquez",
   "Email": "johann.llamas@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "22:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "18:35:00"
  },
  {
   "Nome Completo": "John Kenyon",
   "Email": "john-kenyon@iowacityofliterature.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "06:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "09:00:00"
  },
    
   {
   "Nome Completo": "Cesar Gerardo Carmona Ruiz",
   "Email": "designcero@me.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "09:00:00",
   "Partida (Data)": "11\/07\/2024",
   "Partida ( Hora)": "20:45:00"
  }, 
  {
   "Nome Completo": "Fatema Al Sulaiti",
   "Email": "fhalsulaiti@qm.org.qa",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "12:20:00"
  },
  {
   "Nome Completo": "Colleen Swain",
   "Email": "Colleen.swain@sanantonio.gov",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "06:55:00",
   "Partida (Data)": "11\/07\/2024",
   "Partida ( Hora)": "12:35:00"
  },
  {
   "Nome Completo": "Daichi Tsutsumi",
   "Email": "daichi_223@yahoo.co.jp",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:25:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "00:20:00"
  },
  {
   "Nome Completo": "Peggy Hughes",
   "Email": "peggy@nationalcentreforwriting.org.uk",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "08:30:00"
  },
  {
   "Nome Completo": "Jonathan Mabry",
   "Email": "jmabry@cityofgastronomy.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:40:00",
   "Partida (Data)": "16\/07\/2024",
   "Partida ( Hora)": "12:20:00"
  },
  {
   "Nome Completo": "Maria Elisa Holguin",
   "Email": "maeholguin@fundacionbibliotec.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:33:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "03:55:00"
  },
  {
   "Nome Completo": "Veronika Liebl",
   "Email": "veronika.liebl@ars.electronica.art",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:20:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "10:25:00"
  },
  {
   "Nome Completo": "Henrik Holmskov",
   "Email": "5hh@viborg.dk",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:10:00"
  },
  {
   "Nome Completo": "Bruce Teague",
   "Email": "bteague@iowa-city.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "13:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "18:10:00"
  },
  {
   "Nome Completo": "Martin Sanderhoff",
   "Email": "msand@viborg.dk",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:45:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "12:10:00"
  },
  {
   "Nome Completo": "Kenji Kondo",
   "Email": "k.kondo@kiito.jp",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:50:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "09:05:00"
  },
  {
   "Nome Completo": "Aino Ainali",
   "Email": "aino.ainali@kuhmo.fi",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:20:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Khulood Khoory",
   "Email": "khulood.khoory@dubaiculture.ae",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "17:05:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "11:00:00"
  },
  {
   "Nome Completo": "Eduardo Seijo",
   "Email": "eduardo.seijo@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "18:30:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "03:05:00"
  },
  {
   "Nome Completo": "Andrea Metcalf",
   "Email": "a.metcalf@bendigo.vic.gov.au",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "09:55:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "16:00:00"
  },
  {
   "Nome Completo": "Yoko Kawa",
   "Email": "creativecitykanazawa@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "22:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Maurice KONATE",
   "Email": "lezerbo@yahoo.fr",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:00:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "20:00:00"
  },
  {
   "Nome Completo": "Svein Frydenlund",
   "Email": "svein.frydenlund@hamar.kommune.no",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "23:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Dag Hartman",
   "Email": "dag.hartman@miun.se",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:25:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "08:45:00"
  },
  {
   "Nome Completo": "Mariembe Rachel",
   "Email": "rmariembe@yahoo.fr",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "12:05:00",
   "Partida (Data)": "14\/07\/2024",
   "Partida ( Hora)": "11:45:00"
  },
  {
   "Nome Completo": "Marcos Antonio Campins Robaina",
   "Email": "marcoscampins75@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:20:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "16:05:00"
  },
  {
   "Nome Completo": "Karen Shepherd",
   "Email": "uccnkuching@kuchingcreativecity.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:15:00",
   "Partida (Data)": "30\/07\/2024",
   "Partida ( Hora)": "07:55:00"
  },
  {
   "Nome Completo": "Laura Odegaard",
   "Email": "laura.odegaard@austintexas.gov",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "06:55:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "09:00:00"
  },
  {
   "Nome Completo": "Natalia Echenique",
   "Email": "cityofmusic@concepcion.cl",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "07:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "20:35:00"
  },
  {
   "Nome Completo": "Magdalena Popovic",
   "Email": "magdalena@sarajevocityoffilm.ba",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:15:00"
  },
  {
   "Nome Completo": "Jin Pan",
   "Email": "panj@creativecity.sh.cn",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "22:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:25:00"
  },
  {
   "Nome Completo": "Dwinita Larasati",
   "Email": "titalarasati@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "05:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "10:50:00"
  },
  {
   "Nome Completo": "BEENA PHILIP",
   "Email": "mayorkozhikode@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "03:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Chris McCreery",
   "Email": "mccreeryc@belfastcity.gov.uk",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:00:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "21:25:00"
  },
  {
   "Nome Completo": "Maya Dimerli",
   "Email": "tsunetaka1@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "09:40:00"
  },
  {
   "Nome Completo": "Cory Crossman",
   "Email": "ccrossma@london.ca",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:20:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "16:00:00"
  },
  {
   "Nome Completo": "YANKOTY Noutèhou Charlemagne",
   "Email": "richlys2001@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:30:00",
   "Partida (Data)": "30\/07\/2024",
   "Partida ( Hora)": "09:20:00"
  },
  {
   "Nome Completo": "AGOSSADOU Boris Lougbégnon",
   "Email": "bagossadou@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:30:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Circé Landry DAGANG",
   "Email": "channelpeople10@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "20:15:00",
   "Partida (Data)": "09\/07\/2024",
   "Partida ( Hora)": "20:40:00"
  },
  {
   "Nome Completo": "Teona Jakeli",
   "Email": "creative_city_kutaisi@kutaisi.gov.ge",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:10:00"
  },
  {
   "Nome Completo": "Grettel Killing",
   "Email": "grettel.killing@tallinnlv.ee",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:45:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "06:40:00"
  },
  {
   "Nome Completo": "KAVANAD UTHAMAN BINI",
   "Email": "secretarykkd@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Emma Clesh ATIPO NGAPI",
   "Email": "borel.chardon@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "07:05:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "09:15:00"
  },
  {
   "Nome Completo": "Frédéric NZOKI EPOH",
   "Email": "mairenzokiepohfrederic@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "12:05:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "08:00:00"
  },
  {
   "Nome Completo": "Maria Elisa Dominguez",
   "Email": "ciudad.creativa.caracas@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "08:00:00"
  },
  {
   "Nome Completo": "Tom Legdani Hiis Bergh",
   "Email": "tom.bergh@bergen.kommune.no",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "18:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "15:55:00"
  },
  {
   "Nome Completo": "Gillian Wilkinson McDaniel",
   "Email": "gwmcdaniel@mcges.gov.jm",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:00:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "15:00:00"
  },
  {
   "Nome Completo": "Esmeralda Rios Cardenas",
   "Email": "rios.cardenas@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:35:00"
  },
  {
   "Nome Completo": "RAN KANEUMI",
   "Email": "kaneumiran@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Amélie Nielsen",
   "Email": "amelie.nielsen@rouen.fr",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:10:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "18:15:00"
  },
  {
   "Nome Completo": "Elena FARCA",
   "Email": "international.iasi@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "07:35:00"
  },
  {
   "Nome Completo": "Andrea Dr. Edel",
   "Email": "andrea.edel@heidelberg.de",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:20:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "16:25:00"
  },
  {
   "Nome Completo": "Ryana Parks-Shaw",
   "Email": "deitra.johnson@kcmo.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "22:30:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:15:00"
  },
  {
   "Nome Completo": "JAIR GALVÃO",
   "Email": "jairgalvao@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:50:00",
   "Partida (Data)": "13\/07\/2024",
   "Partida ( Hora)": "21:40:00"
  },
  {
   "Nome Completo": "Jesús Ortega",
   "Email": "jesus_ortega_gr@yahoo.es",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:20:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "15:40:00"
  },
  {
   "Nome Completo": "Morgan Messick",
   "Email": "Morgan.Messick@austintexas.gov",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "06:55:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "09:00:00"
  },
  {
   "Nome Completo": "Edwin Hernando Maldonado Pabon",
   "Email": "edwin.maldonado@cali.gov.co",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:33:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "03:50:00"
  },
  {
   "Nome Completo": "Mark Thompson",
   "Email": "thompson@paducah.travel",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "05:00:00"
  },
  {
   "Nome Completo": "Giuseppe Biagini",
   "Email": "g.biagini@ck.foundation",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:35:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "13:20:00"
  },
  {
   "Nome Completo": "Pamela Burnside",
   "Email": "creativenassau@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:50:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "23:55:00"
  },
  {
   "Nome Completo": "Mikko Salmi",
   "Email": "mikko.salmi@ouka.fi",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "18:00:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "11:55:00"
  },
  {
   "Nome Completo": "Robert Reed Smith",
   "Email": "buzsmith3243@msn.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "17:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:00:00"
  },
  {
   "Nome Completo": "Glenn Denton",
   "Email": "glennddenton@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "05:00:00"
  },
  {
   "Nome Completo": "Sara Meyer",
   "Email": "sara.meyer@norrkoping.se",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "09:45:00"
  },
  {
   "Nome Completo": "Mariana Zagoruiko",
   "Email": "mar.zagoruiko@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:10:00"
  },
  {
   "Nome Completo": "eduardo barroso neto",
   "Email": "barrosodesign@hotmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:00:00",
   "Partida (Data)": "06\/06\/2024",
   "Partida ( Hora)": "13:20:00"
  },
  {
   "Nome Completo": "Alexia De Vito",
   "Email": "a.devito@romacinemafest.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:00:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "13:20:00"
  },
  {
   "Nome Completo": "Hiroyuki Horii",
   "Email": "koyahman@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "Anika Eunhye KIM",
   "Email": "keh5733@dureraum.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "SOYOUNG LEE",
   "Email": "sy1008@dureraum.org",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Bogdana Brylynska",
   "Email": "brylynska.kaf.kult@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:07:00"
  },
  {
   "Nome Completo": "Moonsun Byun",
   "Email": "moonsun007@korea.kr",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Nanako Kobayashi",
   "Email": "nanako7754@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:50:00"
  },
  {
   "Nome Completo": "EUNYOUNG NOH",
   "Email": "creativecityjeonju@korea.kr",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Bensalla Ziad",
   "Email": "zbensalla@casapatrimoine.ma",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "00:45:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "21:20:00"
  },
  {
   "Nome Completo": "HOUNSOU RICHARD LYS",
   "Email": "brutusdos2012@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:15:00",
   "Partida (Data)": "29\/06\/2024",
   "Partida ( Hora)": "23:05:00"
  },
  {
   "Nome Completo": "Thadthong Bhrammanee",
   "Email": "thadthong@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:15:00"
  },
  {
   "Nome Completo": "Masaaki Nagareo",
   "Email": "masaaki_nagareo@city.okayama.lg.jp",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Yoko Monden",
   "Email": "youko_monden@city.okayama.lg.jp",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "14:00:00"
  },
  {
   "Nome Completo": "Osamu Iwata",
   "Email": "bunkashinkou@city.okayama.lg.jp",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
 {
   "Nome Completo": "Nicola MacRae",
   "Email": "macraen@belfastcity.gov.uk",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:00:00",
   "Partida (Data)": "10\/07\/2024",
   "Partida ( Hora)": "17:35:00"
  },
  {
   "Nome Completo": "Sara Vuletic",
   "Email": "sara.vuletic@ns2022.rs",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "21:40:00"
  },
  {
   "Nome Completo": "Hanna Jakku",
   "Email": "hanna.jakku@ouka.fi",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:00:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "11:55:00"
  },
  {
   "Nome Completo": "Sruti Respati",
   "Email": "srutirespati26@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:55:00"
  },
  {
   "Nome Completo": "JOSE LUIS MARTÍNEZ",
   "Email": "animarrufo94@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:35:00"
  },
  {
   "Nome Completo": "Alejandro Ivan Ruz Castro",
   "Email": "emydenis@hotmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "01:30:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "14:05:00"
  },
  {
   "Nome Completo": "Violeta Djerkovic",
   "Email": "violeta.djerkovic@ns2022.rs",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "21:20:00"
  },
  {
   "Nome Completo": "Shashi Bala",
   "Email": "chief.bdd@mcgm.gov.in",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:00:00"
  },
  {
   "Nome Completo": "Nemanja Milenkovic",
   "Email": "kabinet@ns2022.rs",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "21:20:00"
  },
  {
   "Nome Completo": "Reidar Digranes",
   "Email": "Reidar.Digranes@bergen.kommune.no",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "18:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "15:55:00"
  },
  {
   "Nome Completo": "Dalibor Rožić",
   "Email": "ivan.filipovic@novisad.rs",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "21:20:00"
  },
  {
   "Nome Completo": "Natalie Moore",
   "Email": "natalie.moore@bristol.gov.uk",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "23:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:10:00"
  },
  {
   "Nome Completo": "Alan Duggan",
   "Email": "alan@ardan.ie",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "22:00:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "13:35:00"
  },
  {
   "Nome Completo": "Sabrina de Jesus Romero Lope",
   "Email": "emydenis14@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "01:30:00",
   "Partida (Data)": "03\/07\/2024",
   "Partida ( Hora)": "14:05:00"
  },
  {
   "Nome Completo": "Roy Rajan",
   "Email": "royrajan719@yahoo.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "01:44:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "23:05:00"
  },
  {
   "Nome Completo": "Mauricio Castro",
   "Email": "mcastro@concepcion.cl",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "07:35:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "12:20:00"
  },
  {
   "Nome Completo": "Wong Ming Kong",
   "Email": "wmk@mbks.gov.my",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:20:00"
  },
  {
   "Nome Completo": "Hong Seng Wee",
   "Email": "datoweehongseng@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:20:00"
  },
  {
   "Nome Completo": "Alvaro Ortiz",
   "Email": "alcaldia@concepcion.cl",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "07:35:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "12:20:00"
  },
  {
   "Nome Completo": "Paige Ellis",
   "Email": "paige.ellis@austintexas.gov",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "08:55:00"
  },
  {
   "Nome Completo": "Takeshi Uedera",
   "Email": "kanazawakeizai@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "22:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Gaura Mancacaritadipura",
   "Email": "gaura@cbn.net.id",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "07\/06\/2024",
   "Partida ( Hora)": "11:55:00"
  },
  {
   "Nome Completo": "Khadija El Bennaoui",
   "Email": "kbennaoui@dctabudhabi.ae",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:50:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "16:00:00"
  },
  {
   "Nome Completo": "Thi Lan Anh Pham",
   "Email": "Lananhbql@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "05:10:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "10:50:00"
  },
  {
   "Nome Completo": "Sérgio Onofre Seixas",
   "Email": "economiacriativapenedo@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:50:00",
   "Partida (Data)": "08\/07\/2024",
   "Partida ( Hora)": "21:20:00"
  },
   {
   "Nome Completo": "Angelique Bouffard",
   "Email": "angelique.bouffard@ville.quebec.qc.ca",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "23:10:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "18:10:00"
  },
  {
   "Nome Completo": "Ana Karina Hernández",
   "Email": "natcom.venezuela3@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "09:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:25:00"
  },
  {
   "Nome Completo": "Tanya Black",
   "Email": "tanya.black@wellingtonnz.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:25:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:05:00"
  },
  {
   "Nome Completo": "Fabian Bedon Samaniego",
   "Email": "fabian.bedon@montecristi.gob.ec",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "18:18:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "23:23:00"
  },
  {
   "Nome Completo": "Luis Jonathan Toro Largacha",
   "Email": "fabedon@ucm.es",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "17:00:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "21:10:00"
  },
  {
   "Nome Completo": "Christian Palma",
   "Email": "fabian.bedon@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:00:00",
   "Partida (Data)": "04\/07\/2024",
   "Partida ( Hora)": "21:00:00"
  },
  {
   "Nome Completo": "Abdulelah Jali",
   "Email": "ajali@moc.gov.sa",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "07:30:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "07:30:00"
  },
  {
   "Nome Completo": "Dimas Pratama Wijayanto",
   "Email": "dimsphotography39@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:55:00"
  },
  {
   "Nome Completo": "sooyun yang",
   "Email": "sooyunyang0919@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "09:00:00"
  },
  {
   "Nome Completo": "Sileymane Sow",
   "Email": "sileymane.sow@rouen.fr",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:05:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "11:50:00"
  },
  {
   "Nome Completo": "Gitau Thabanja",
   "Email": "gitauthabanjan@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "07:15:00"
  },
  {
   "Nome Completo": "CHO YEON BAK",
   "Email": "cksdid1121@naver.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "Maria Luiza Lourenço Marra",
   "Email": "maria.marra@buriti.df.gov.br",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:45:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "08:40:00"
  },
  {
   "Nome Completo": "Ayanda Christine Makhaye",
   "Email": "yandie25@live.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:20:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "16:35:00"
  },
  {
   "Nome Completo": "Bárbara Menucci",
   "Email": "barbaramenucci@pbh.gov.br",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:00:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:00:00"
  },
  {
   "Nome Completo": "Marcos Vinicius Boffa",
   "Email": "marcos.boffa@pbh.gov.br",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:00:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "09:00:00"
  },
  {
   "Nome Completo": "Caroline Figueiredo",
   "Email": "carolinetoledo@pbh.gov.br",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:00:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "11:00:00"
  },
  {
   "Nome Completo": "CHO YEON BAK",
   "Email": "cksdid1121@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:40:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "16:20:00"
  },
  {
   "Nome Completo": "AMBER THOMPSON",
   "Email": "ryomto+main@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:20:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "HONG CHUAN TEO",
   "Email": "thomasteo.mbks@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "12:20:00"
  },
  {
   "Nome Completo": "Sabina Tupikova",
   "Email": "international.cooperation.kh@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "18:00:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "06:25:00"
  },
  {
   "Nome Completo": "Kurt Ole Linn",
   "Email": "kurt.ole.linn@innlandetfylke.no",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:00:00",
   "Partida (Data)": "10\/07\/2024",
   "Partida ( Hora)": "10:20:00"
  },
  {
   "Nome Completo": "Jeyran Jafarova",
   "Email": "j.jafarova@culture.gov.az",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "08:10:00"
  },
  {
   "Nome Completo": "Christopher Pappas",
   "Email": "mayorpa@umngeni.gov.za",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "11:20:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "16:35:00"
  },
  {
   "Nome Completo": "David Kipkemoi Kones",
   "Email": "kisharasetluget@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "10:35:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "08:30:00"
  },
  {
   "Nome Completo": "Manuela Gomezjurado",
   "Email": "manuela.gomezjurado@munivalpo.cl",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:55:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "20:35:00"
  },
  {
   "Nome Completo": "Daniel Ramírez",
   "Email": "daniel.ramirez@munivalpo.cl",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:55:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "20:35:00"
  },
  {
   "Nome Completo": "Haiou Wang",
   "Email": "48901438@qq.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "08:00:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "08:35:00"
  },
  {
   "Nome Completo": "Milada Šukalo",
   "Email": "sinisa.marcetic@banjaluka.rs.ba",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "22:45:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "06:15:00"
  },
  {
   "Nome Completo": "Cory Crossman",
   "Email": "coryscrossman@gmail.com",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "21:20:00",
   "Partida (Data)": "07\/07\/2024",
   "Partida ( Hora)": "16:00:00"
  },
  {
   "Nome Completo": "Jarmo Husso",
   "Email": "marjo.korpela@ouka.fi",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "19:00:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "11:55:00"
  },
  {
   "Nome Completo": "Ana Eugenia Vázquez Alemán",
   "Email": "hola@queretarocreativo.mx",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "14:30:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "18:35:00"
  },
  {
   "Nome Completo": "Aziz Bobohojiev",
   "Email": "abobohojiev@qm.org.qa",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:55:00",
   "Partida (Data)": "05\/07\/2024",
   "Partida ( Hora)": "19:40:00"
  },
  {
   "Nome Completo": "Barbara Bolognani",
   "Email": "BolognaniB@state.gov",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "13:15:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "13:50:00"
  },
  {
   "Nome Completo": "Tamara Zelenovic Vasiljevic",
   "Email": "tamara.zelenovic@ns2022.rs",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "16:35:00",
   "Partida (Data)": "06\/07\/2024",
   "Partida ( Hora)": "21:20:00"
  },
  {
   "Nome Completo": "Madeleine de Young",
   "Email": "maddy@maorilandfilm.co.nz",
   "Chegada (Data)": "30\/06\/2024",
   "Chegada (Hora)": "15:45:00",
   "Partida (Data)": "07\/06\/2024",
   "Partida ( Hora)": "12:00:00"
  },
  
  
 ]
