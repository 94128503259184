// src/pages/CreateTicketPage.js
import React from 'react';
import CreateNewTicket from '../components/CreateNewTicket';

const CreateTicketPage = () => {
  return (
    <div>
      <CreateNewTicket />
    </div>
  );
};

export default CreateTicketPage;